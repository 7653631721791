import Vue from 'vue'
import router from '../router'
import { Auth0Plugin } from '../auth'

Vue.use(Auth0Plugin, {
  domain: 'magentine-healthcare.eu.auth0.com',
  clientId: '4550eqFVv6gFoW3s2bv9TczZQrTEfL76',
  audience: 'https://admin.testrapi.de/api',
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
})
