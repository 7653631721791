<template>
  <div id="app">
    <b-container> <b-img src="@/assets/logo.png" /></b-container>
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'App',
  }
</script>
