import Vue from 'vue'
import Vuex from 'vuex'
import fetch from 'cross-fetch'
Vue.use(Vuex)
export default new Vuex.Store({
  state: { establishments: [], users: [], isBusy: false },
  mutations: {
    establishments(state, value) {
      state.establishments = value
    },
    users(state, value) {
      state.users = value
    },
  },
  actions: {
    async initEstablishment({ state, commit }, token) {
      state.isBusy = true
      fetch('/api/establishment', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        const establishments = await res.json()
        let _establishments = establishments.map((e) => ({
          value: e.id,
          text: e.name,
        }))
        _establishments.unshift({ id: null, text: "Choix de l'établissement" })
        commit('establishments', _establishments)
        state.isBusy = false
      })
    },
    async fetchUsers({ state, commit }, token) {
      state.isBusy = true
      fetch('/api/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        const users = await res.json()
        users.sort((f, s) => f.createdAt > s.createdAt)
        commit('users', users)
        state.isBusy = false
      })
    },
  },
})
