<template>
  <b-container class="home">
    <b-jumbotron
      header="MonTestRapide"
      lead="Administration des comptes utilisateurs"
      bg-variant="primary"
      text-variant="light"
    >
      <b-button @click="login()">Se connecter</b-button>
    </b-jumbotron>
  </b-container>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
    methods: {
      async login() {
        await this.$auth.loginWithPopup()
        this.$router.push('/phonebook')
      },
    },
  }
</script>

<style>
  .container {
    margin-top: 4ex;
  }
</style>
